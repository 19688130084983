@import "boxicons/css/boxicons.min.css";
@import 'remixicon/fonts/remixicon.css';
@import '../node_modules/ngx-toastr/toastr.css';
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: 'Paladins Straight';
  src: url('./assets/fonts/PaladinsStraight.woff2') format('woff2'),
      url('./assets/fonts/PaladinsStraight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Chandler Mountain';
  src: url('./assets/fonts/ChandlerMountain-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/ChandlerMountain-ExtraBold.woff') format('woff'),
      url('./assets/fonts/ChandlerMountain-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --primary-color: #500200;
}

html,body {height: 100%;color: #565973 !important;font-family: "Manrope", sans-serif !important;}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {margin-top: 0; margin-bottom: 1rem !important; font-weight: 800 !important; line-height: 1.3 !important; color: #0b0f19 !important;}
.card-title {color: #565973 !important;}
.paladins-font {font-family: 'Paladins Straight';font-weight: 500 !important;}
.chandler-font {font-family: 'Chandler Mountain';font-weight: 800 !important;}
.gilroy-font {font-family: 'Gilroy';font-weight: 500 !important;}
.roboto-font {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.poppins-font {font-family: 'Poppins', sans-serif !important;}
.viga-font {font-family: 'Viga', sans-serif;}
.nav-link:hover, .nav-link.active {color: var(--primary-color) !important;}
.btn-gold {background-color: #C0A770 !important;color: #000 !important;}
.btn-gold:hover {background-color: #fff !important;border: 1px solid #C0A770 !important;}
.bg-dark-border {background-color: rgba(0, 0, 0, 0.7) !important;border: 1px solid #92bde3 !important;}
.bottom-border-gold {border-bottom: 10px solid #92bde3 !important;}
.btn-primary-color { background-color: #500200 !important; color: #fff !important; border: 1px solid #500200 !important; }
.btn-primary-color:hover {background-color: #fff !important; color: #500200 !important;}
.btn-carolina-blue {background-color:var(--primary-color)!important;color: #000 !important;}
.btn-carolina-blue:hover {background-color: #fff !important;border: 1px solid #93bde3 !important;}
.btn {display: inline-flex !important;align-items: center;justify-content: center; font-weight: 600 !important; padding: 0.625rem 1.75rem !important;}
.btn-sm, .btn-group-sm>.btn {padding: 0.456rem 1rem !important;font-size: 0.8rem !important;}
.btn-lg, .btn-group-lg>.btn {padding: 0.785rem 2rem !important;font-size: 1rem !important;}
a:hover {text-decoration: none !important;}
.font-primary-color {color: #500200 !important;}
.font-white {color: #fff !important;}
.font-black {color: #000 !important;}
.font-grey {color: #7a7a7a !important;}
.font-gold {color: #C0A770 !important;}
.font-carolina-blue {color: var(--primary-color) !important;}
.font-30 {font-size: 30px;}
.font-18 {font-size: 18px !important;}
.font-14 {font-size: 14px;}
.fs-xl {font-size: 1.25rem !important;}
.fs-lg {font-size: 1.125rem !important;}
.fs-sm {font-size: 0.875rem !important;}
img, figure {max-width: 100%;height: auto;vertical-align: middle;}
.sec-spacing {padding: 100px 0;}
.hero-headline {padding: 25px 0 0 0;line-height: 70px !important;}
.opacity-70 {opacity: .7 !important;}
.navbar-toggler-icon::before {background-color: #33354d;}
.bg-repeat-0 {background-repeat: no-repeat !important;}
.bg-position-bottom-center {background-position: bottom center !important;}
.bg-size-cover {background-size: cover !important;}
.form-label {margin-bottom: 0.3125rem; font-size: 0.875rem; font-weight: 600; color: #0b0f19;}
.form-control {line-height: 1.6; padding: 0.625rem 1rem !important;font-size: 0.875rem; color: #565973 !important;}
.form-control:focus {color: #565973 !important;border-color: rgba(99, 102, 241, .35) !important; box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(99, 102, 241, .2) !important;}
::-webkit-input-placeholder{color: #b4b7c9 !important;}
::-moz-placeholder{color: #b4b7c9 !important;}
::-ms-input-placeholder{color: #b4b7c9 !important;}
/*---- Slider -----*/
.carousel-inner {/*display: block !important;*/ justify-content: center; padding: 0 3rem;}
.carousel-indicators { display: none !important;}
/* .carousel-indicators button { background-color: var(--primary-color) !important; bottom: -2rem !important; height: 5px !important;  width: 5px !important; border-radius: 100%;} */
/* .carousel-indicators .active {width: 30px !important;} */
.carousel-item .item {box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; border-radius: 0.5rem; overflow: hidden; margin: 0 10px;}
.carousel-control-next, .carousel-control-prev {opacity: 1 !important; width: auto !important; top: 50% !important; bottom: auto !important; transform: translateY(-50%) !important;}
.carousel-control-prev-icon, .carousel-control-next-icon {background-color: var(--primary-color) !important;font-size: 1.25em;color: #000 !important;height: 36px !important;width: 36px !important;border-radius: 100px; line-height: 36px; background-size: 50% 50% !important;}
.carousel-item {padding: 1rem 0;}
.carousel-item .item {box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; border-radius: 0.5rem; overflow: hidden;}
/* .carousel-control-prev {left: 30px !important;}
.carousel-control-next {right: 30px !important;} */
.multilist-margin {margin: 0 !important;}

#carouselStaff .carousel-item .item {box-shadow: none;}
#carouselStaff .carousel-control-next, #carouselStaff .carousel-control-prev {top: 40% !important; transform: translateY(-40%) !important;}

/*-  Gallery */
.gallery-item {position: relative; display: block; overflow: hidden; text-decoration: none;}
.gallery-item img {display: block;width: 100%;}
.gallery-item::before {position: absolute;z-index: 1;display: block;width: 100%;height: 100%;content: "";opacity: 0;transition: opacity 0.3s ease-in-out;background-color: rgba(11, 15, 25, 0.4);}
.gallery-item::after {position: absolute;top: 50%;left: 50%;z-index: 5;display: block;text-align: center;font-family: "boxicons";content: "\ec90";width: 4.5rem;height: 4.5rem;transform: translate(-50%, -50%) scale(0.3);color: #fff; font-size: 2rem; line-height: 4.5rem;}
.gallery-item .gallery-item-caption {position: absolute;top: 0;left: 0;z-index: 5;display: block;width: 100%;transform: translateY(0.5rem);color: #ffffff;padding: 1rem;}
.gallery-item::after, .gallery-item .gallery-item-caption {opacity: 0;transition: all 0.3s ease-in-out;}
.gallery-item:hover::before, .gallery-item.is-hovered::before, .gallery-item:hover::after, .gallery-item.is-hovered::after, .gallery-item:hover .gallery-item-caption, .gallery-item.is-hovered .gallery-item-caption {opacity: 1;transform: none;}
.gallery-item:hover::after, .gallery-item.is-hovered::after{transform: translate(-50%, -50%);}
.gallery-item.video-item::after {padding-top: 0.0625rem; padding-left: 0.25rem; content: "\ebbd"; border-radius: 50%; opacity: 1;transform: none;}
.lg-item:focus, .lg:focus, .lg-outer:focus, .lg-inner:focus {outline: none;}
.card-hover:hover .card-img-overlay {opacity: 1;}
.card-hover .card-img-overlay {opacity: 0;transition: opacity .3s ease-in-out;}
/* .navbar-dark {background: #0b0f19;} */
.bg-position-top-center {background-position: top center;}
.camp-card { box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }
.camp-card img {width: 90%;}

.hero-section {min-height: 600px; background-position: center; background-size: cover; border-bottom: 10px solid var(--primary-color); position: relative;}
.nav-logo-update {height: 100px; width: 100px !important;}
.org-primary-logo {max-height: 200px !important;}

.btn-ctm-primary-color { background-color: var(--primary-color) !important; color: #fff !important; border: 1px solid var(--primary-color) !important; }
.btn-ctm-primary-color:hover {background-color: #fff !important; color: #500200 !important;}
.ctm-scroll-top-button {position: fixed; bottom: 20px; right: 20px; background-color: var(--primary-color); color: white; border: none; border-radius: 50%; cursor: pointer; transition: opacity 0.3s; height: 2.75rem; width: 2.75rem; display: flex; align-items: center; justify-content: center;}
footer {border-top: 10px solid var(--primary-color); background: #000; font-family:"Manrope", sans-serif;}
.error{
  font-size: 150px;
  color: #ffffff !important;
  text-shadow:
    1px 1px 1px #C0A770,
    2px 2px 1px #C0A770,
    3px 3px 1px #C0A770,
    4px 4px 1px #C0A770,
    5px 5px 1px #C0A770,
    6px 6px 1px #C0A770,
    7px 7px 1px #C0A770,
    8px 8px 1px #C0A770,
    /* 25px 25px 8px rgba(255,255,255, 0.1),   */
    25px 25px 8px #000;

}

.ctmptr{
  cursor: pointer;
}

@media only screen and (max-width: 991px){
  .sec-spacing {padding: 60px 0;}
  .hero-headline {padding: 25px 0 0 0;line-height: 48px !important;}
  .nav-link {padding:  0.535rem 0 !important;}
  .camp-card img {width: 100% !important;}
}
@media only screen and (max-width: 575px){
  .tournament-hero h1 {font-size: 1.5rem;}
  .nav-logo-update{ height: 80px !important; width: 80px !important;}
  .display-1{ font-size: 2.2rem !important;}
}
@media(min-width: 992px) {
  .nav-link {padding: 0.875rem !important;}
}

@media (max-width: 767px){
.container-md, .container-sm, .container {
    max-width: 95% !important;
}}
@media (max-width: 500px){
.container-sm, .container {
    max-width: 97% !important;
}}

.loading-text[_ngcontent-ng-c2720958613]{
  position: fixed !important;
  top: 50% !important;
}

.hero-section { background: #000; background-repeat: no-repeat; background-size: cover; background-position: center center; }
@media (max-width: 767px){
  div .contact-logo-mobile-center { margin-top: 2rem; }
}
.dropdown-box {
  position: relative;
  z-index: 5;
}
.ng-dropdown-panel {
  display: contents !important;
}
.dropdown-box .ng-dropdown-panel .ng-dropdown-panel-items {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #e9ecef;
  border-top: 0;
  border-radius: 0.25rem;
}
.custom-select-dropdown .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto;
}
.dropdown .table-dropdown.dropdown-toggle:after,
.btn-group .table-dropdown.dropdown-toggle:after {
    display: none;
}
.horizontal-menu .navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
  border: 0;
}
.custom-dropdown-box .ng-select-container {
  min-height: 38px;
  height: 38px;
}
@media only screen and (max-width: 1400px) {
  .custom-dropdown-box .ng-select-container {
      min-height: 32px;
      height: 32px;
  }
  .custom-dropdown-box .ng-select-container .ng-value-container {
      padding-top: 0 !important;
      padding-left: 0 !important;
  }
  .custom-dropdown-box .ng-select-container .ng-value-container .ng-placeholder {
      top: 6px !important;
      left: 0.5rem !important;
      padding-left: 0.5rem;
  }
  /* // .job-top-info h5 { font-size: 18px; } */
}
.custom-select-dropdown .ng-select-container .ng-value-container .ng-value {
  display: inline-block;
  height: auto;
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto;
}

/* Ensures that the dropdown items do not exceed a certain height */
.custom-select-dropdown .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto;
}

/* Optional: Adjusts the height of the individual items if needed */
.custom-select-dropdown .ng-option {
  height: auto;
  padding: 4px 10px; /* Adjust padding as needed */
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{
  padding: 9px !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{
  padding: 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{
  position: inherit !important;
  padding-bottom: 0;
}

.ctmSelect .ng-dropdown-panel .scroll-host{
position: absolute !important;
width: 100%;
}

.center-ctmcontent {
  height: 100vh;
  display: flex;
  align-items: center;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover{
  color: rgba(57, 57, 57, 0.3) !important;
}

label { display: block; font-weight: 400; letter-spacing: 0.02rem; margin-bottom: 0.3rem; font-family: 'Poppins'; }
.ng-select .ng-select-container, div .form-control { min-height:40px; }
.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value, .ng-select .ng-select-container { font-size: 0.8rem; font-weight: 600; letter-spacing: 0.02rem; font-family: 'Poppins'; }
div .form-control { font-size: 0.8rem; font-weight: 400; letter-spacing: 0.02rem; font-family: 'Poppins'; height: 46px; }
.ng-select .ng-select-container .ng-value-container .ng-placeholder { font-size: 0.8rem; font-weight: 400; letter-spacing: 0.02rem; font-family: "Poppins"; color: #b4b7c9; }
.ng-select.ng-select-single .ng-select-container { height: 46px; }
.applyCredits input { background:#fff; padding:8px 88px 8px 16px !important; height: 46px; font-size: 0.8rem; font-weight: 400; letter-spacing: 0.02rem; font-family: 'Poppins'; }
.applyCredits .apply p { font-size: 0.8rem; width: 73px; justify-content: center; font-weight: 600; letter-spacing: 0.02rem; font-family: 'Poppins'; }
.offcanvas-body { font-family: 'Poppins'; font-size: 0.9rem; }
.offcanvas-body h6 { font-weight: 700 !important; letter-spacing: 0.03rem; }
.nextButton { font-size: 0.9rem; font-weight: 500; letter-spacing: 0.04rem; font-family: 'Poppins'; min-height: 40px; }
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input { top:13px; }
.video-container {position: absolute; top: 0;left: 0;width: 100%;height: 100%;z-index: -1; background: #000; opacity: 0.7;}
.video-container video {position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;}
#hero-container-section {position: relative;z-index: 0;}
#hero-container-section .min-vh-100 {min-height: calc(100vh - 100px) !important;}

.video-overlay-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
}

.play-button {
  font-size: 3em;
  pointer-events: none;
  color: #787878;
}
.carouselPartnersSlider .carousel-inner { padding:0 2rem; }
.carouselPartnersSlider .carousel-inner .carousel-item { min-width:33.333333%; max-width:33.333333%; }
@media (max-width: 991px){
  .carouselPartnersSlider .carousel-inner { justify-content: start; }
    .carouselPartnersSlider .carousel-inner .carousel-item { min-width:50%; max-width:50%; }
  }

@media (max-width: 767px){
    .carouselPartnersSlider .carousel-inner .carousel-item { min-width:100%; max-width:100%; }

}

.hero-headline.poppins-font, .hero-headline.roboto-font { line-height: 1.1 !important; }
.hero-headline.gilroy-font { line-height: 1 !important; }
#hero-container-section { background-attachment: fixed; background-position: center center !important; background-repeat: no-repeat !important; background-size: cover !important; }


.modal .modal-modal-fullscreen { max-width: 100%; margin: 0; height: 100%; }
.modal-modal-fullscreen .modal-content { background: rgba(0, 0, 0, 0.7); border: 0; border-radius: 0; height: 100%; }
.modal-modal-fullscreen .modal-content .component-host-scrollable { height: 100%; }
.modal-modal-fullscreen .modal-header, .modal-modal-fullscreen .modal-body, .modal-modal-fullscreen .modal-footer { border: 0; border-radius: 0; }
.modal-modal-fullscreen .modal-header, .modal-modal-fullscreen .modal-footer { background-color: rgba(0, 0, 0, 0.2); }
.modal-modal-fullscreen .modal-header .modal-title { color: #fff !important; }
.modal-modal-fullscreen .modal-body { text-align: center; display: flex; align-items: center; justify-content: center; }
.modal-modal-fullscreen .modal-body img { max-width: 100%; max-height: 100%; height: calc(100vh - 200px) !important; width: auto !important; margin: auto; }
.modal-modal-fullscreen .modal-body video { max-width: 100%; max-height: 100%; height: calc(100vh - 200px) !important; width: auto !important; margin: auto; }
.position-select .ng-select .ng-select-container, div .form-control { min-height:46px; }

.swal2-popup.swal2-modal.custom-popup-class {
  width: 22em;
  padding-bottom: 80px;
  background-color: #ffffff;
  display: grid;
}

/* custom height */
.h-5 { height: 5rem }
.h-7 { height: 7rem }
.h-10 { height: 10rem }
.h-20 { height: 20rem }
.h-30 { height: 30rem }
.h-40 { height: 40rem }